/**
 * Load data that's used globally, like defaults, social media, etc.
 *
 * @returns {Object} With globals, and fetch method.
 */
export const useDefaults = () => {
	const defaults = useState('defaults', () => { });
	const socials = useState('socialMedia', () => { });

	const setDefaults = (data) => {
		defaults.value = data;
	};

	const setSocialMedia = (data) => {
		socials.value = data;
	};

	let data = { data: null };
	const fetchDefaults = async () => {
		if (defaults.value && socials.value) {
			return;
		}

		try {
			data = await useWebsiteFetch('defaults');
			setDefaults(data.data);

			data = await useWebsiteFetch('socialmedia');
			setSocialMedia(data.data);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	return {
		defaults,
		socials,
		fetchDefaults,
	};
};
